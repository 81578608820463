.login-component {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: 20%;
  padding-top: 40px;
}

.login-note {
  text-align: center;
  margin-top: 24px;

  color: #8C8C8C;

}

.login-form-title {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.0447309px;
  color: #533895;
  margin-bottom: 32px;
}

.login-form-description {
  text-align: center;
  margin-bottom: 24px;
}

.login-logo-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.login-link {
  text-align: left;
  margin-bottom: 20px;
  font-size: small;
}

.login-form {
  max-width: 352px;
  width: 100%;
}

.login-form .ant-form-item {
  margin-bottom: 16px;
}

.login-form .ant-form-item-label {
  padding-bottom: 4px;
}

.forgot-password-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.site-form-item-icon {
  color: #533895;
}

.login-button {
  width: 100%;
  height: 40px;
  margin-top: 8px;
}

.agree-block {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}

.agree-block p {
  margin-left: 8px;
}

@media screen and (max-width: 992px) {
  .login-component {
    padding-right: 0;
  }
}

@media screen and (max-width: 575px) {

  .login-component {
    padding: 0 20px;
  }
}